export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'AgreementsUser',
            title: 'Пользовательское соглашение'
        },
        {
            id: 2,
            name: 'AgreementsPrivacy',
            title: 'Политика конфиденциальности'
        }
    ]
}


